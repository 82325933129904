import { apiFetch, useApiFetch } from '~/utils/fetch/fetchApi';

export const userApi = {
  signIn,
  register,
  activate,
  restore,
  restorePassword,
  refreshToken,
  fetchUser,
  fetchClaims,
  logout,
};

interface AuthData {
  email: string;
  password: string;
}

interface RegiterData {
  email: string;
  password: string;
  passwordAgain: string;
  type: string;
}

interface restoreData {
  password: string;
  passwordAgain: string;
}

function signIn(data: AuthData) {
  const { $i18n } = useNuxtApp();
  const { locale } = $i18n;
  return $fetch('/front-api/auth/login', {
    method: 'POST',
    body: data,
    query: { locale: locale.value },
  });
}

function logout() {
  return $fetch('/front-api/auth/logout', {
    method: 'POST',
  });
}

function register(data: RegiterData) {
  return apiFetch('/api/v1/users/signup/', {
    method: 'POST',
    body: data,
  });
}

function activate(token: string) {
  return apiFetch(`/api/v1/users/activate/${token}/`, {
    method: 'POST',
  });
}

function restore(email: string) {
  return apiFetch('/api/v1/users/restore/', {
    method: 'POST',
    body: {
      email,
    },
  });
}

function restorePassword(token: string, data: restoreData) {
  return apiFetch(`/api/v1/users/restore/${token}/`, {
    method: 'POST',
    body: data,
  });
}

function refreshToken() {
  return useFetch('/front-api/auth/refresh');
}

function fetchUser() {
  return useApiFetch('/api/v1/users/me/');
}

function fetchClaims({ page, size }: { page: number; size: number }) {
  return apiFetch(`/api/v1/users/me/claims/`, {
    query: {
      page,
      size,
    },
  });
}
